import React, { useEffect, useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { isServer } from '@sitecore-jss/sitecore-jss';

import { VideoWrapper, VideoWrapperInner } from './Video.styles';
import { useAsyncScript, useObserver } from 'hooks';
import PlayBtnSvg from "./InlineSvg/VideoPlayBtn.jsx";
const youtubeAPI = 'https://www.youtube.com/iframe_api';

const waitForYoutube = () =>
    new Promise((resolve, reject) => {
      (function checkForYoutube() {
        if (typeof window !== 'undefined' && window?.YT?.ready && typeof window.YT.ready !== 'undefined')
          return resolve();
        setTimeout(checkForYoutube, 100);
      })();

      //  timeout to stop checking after 10 seconds
      setTimeout(() => {
        reject(new Error('Loading YouTube API timed out.'));
      }, 10000);
    });

const Video = ({ youtubeId, width = '960', height = '540',poster, ratio = [16, 9] }) => {
  const [scriptLoaded, scriptError] = useAsyncScript({
    src: youtubeAPI,
    crossOrigin: true,
  });

  const videoRef = useRef(null);
  const [player, setPlayer] = useState(null);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [vidPoster, setVidPoster] = useState(poster || (youtubeId ? `http://img.youtube.com/vi/${youtubeId}/maxresdefault.jpg` : ''));

  const handlePosterError = () => {
    setVidPoster(`https://img.youtube.com/vi/${youtubeId}/default.jpg`);
  };


  const loadVideo = useCallback(() => {
    if (!youtubeId || typeof window === 'undefined') return;
    window.YT.ready(() => {
      const player = new window.YT.Player(`youtube-${youtubeId}`, {
        videoId: youtubeId,
        height,
        width,
        events: {
          onReady: (event) => {
            event.target.playVideo();
          }
        },
        playerVars: {
          autoplay: 1,
          modestbranding: 1,
          rel: 0
        },
      });
      setPlayer(player);
      setVideoLoaded(true);
    });
  }, [youtubeId, height, width]);

  const handlePlayClick = () => {
    if (!videoLoaded && scriptLoaded) {
      loadVideo();
    }
  };

  useEffect(() => {
    return () => {
      if (player) {
        player.destroy();
      }
    };
  }, [player]);

  return (
      <VideoWrapper ref={videoRef} className="video-wrapper">
        <VideoWrapperInner ratio={ratio}>
          {!videoLoaded && (
              <div
                  className="video-placeholder"
                  style={{ backgroundImage: `url(${vidPoster})` }}
                  onClick={handlePlayClick}
              >
                <img src={vidPoster} className="video-poster-fallback" onError={handlePosterError} alt="Video poster" style={{ display: 'none' }} />
                <button className="play-video-button" aria-label="Play video">
                  <span className="self"><PlayBtnSvg/></span>
                </button>
              </div>
          )}
          <div id={`youtube-${youtubeId}`} />
        </VideoWrapperInner>
      </VideoWrapper>
  );
};

export default Video;

Video.propTypes = {
  youtubeId: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  ratio: PropTypes.array,
  poster: PropTypes.string,
};